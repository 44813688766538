import { nanoid } from "nanoid";
import { useEffect } from "react";
import { useSession, signIn } from "next-auth/react";
import { Page, PageMessage, FreeChunk, start } from "@/components/page";
import { signal, useSignal } from "@preact/signals-react";
import { NavLink, A } from "@/components/links";
import Button from "@/components/button";
import { shuffle } from "@/lib/random";
import TextInput from "@/components/textinput";
import { getAuthProps } from "@/pages/api/auth/_authprops";
import imageFiles from "@/public/world-wanderer/cities/image-index.json";
import type { ImageDetails } from "@/lib/world-wanderer/imageindextype";

const origin = typeof window !== "undefined" ? window.location.origin : "";

type FilesShuffled = {
  people: ImageDetails[];
  places: ImageDetails[];
  factions: ImageDetails[];
};

const OPENROUTER_ENDPOINT = `https://openrouter.ai/auth?callback_url=${encodeURIComponent(
  origin,
)}/api/auth/openrouter-callback`;

type Session = {
  sessionId: string;
  characterName?: string;
  pendingCharacterName?: string;
  familyName?: string;
  pendingFamilyName?: string;
  year?: number;
  pendingYear?: number;
  locationName?: string;
  pendingLocationName?: string;
  size?: number;
  created: Date;
  updated: Date;
};

const sessions = signal<Session[] | null>(null);
const overrideSessionId = signal<string>("");

function Home({
  newSessionId,
  filesShuffled,
}: {
  newSessionId: string;
  filesShuffled: FilesShuffled;
}) {
  const { data: userSession } = useSession();
  useEffect(() => {
    if (sessions.value === null) {
      loadSessions();
    }
  }, []);
  function onNewSession() {
    setTimeout(() => {
      overrideSessionId.value = `r-${nanoid()}`;
    }, 500);
  }
  if (userSession === undefined) {
    // It's null when logged out, undefined when not yet determined
    return (
      <Page title="World Wanderer">
        <PageMessage>Loading...</PageMessage>
      </Page>
    );
  }
  if (!userSession) {
    return <LoggedOutHome filesShuffled={filesShuffled} />;
  }
  if (!sessions.value) {
    return (
      <Page title="World Wanderer">
        <PageMessage>Loading...</PageMessage>
      </Page>
    );
  }
  if (sessions.value.length === 0) {
    return <NewBeginning newSessionId={newSessionId} filesShuffled={filesShuffled} />;
  }
  return (
    <Page title="World Wanderer">
      <div>
        <div className="mb-4">
          <NavLink
            onClick={onNewSession}
            href={`/start/${encodeURIComponent(overrideSessionId.value || newSessionId)}`}
          >
            Create a a new character
          </NavLink>
        </div>
        <div>
          <div className="text-xl font-bold">Recent characters</div>
          {sessions.value === null && <div>Loading...</div>}
          {sessions.value?.length === 0 ? (
            <div>No recent characters</div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Character</th>
                  <th>Location</th>
                </tr>
              </thead>
              <tbody>
                {sessions.value?.map((s, i) => {
                  let link = `/play/${encodeURIComponent(s.sessionId)}/auto`;
                  let linkText = "Continue to roleplay overview";
                  if (!s.characterName) {
                    link = `/start/${encodeURIComponent(s.sessionId)}`;
                    linkText = "Continue character creation";
                  }
                  if (!s.characterName && !s.pendingCharacterName) {
                    return null;
                  }
                  return (
                    <tr key={i}>
                      <td className="border-t-2 pr-4 py-4">
                        {s.characterName || s.pendingCharacterName}{" "}
                        {s.familyName || s.pendingFamilyName}
                      </td>
                      <td className="border-t-2 pr-4">{s.locationName || s.pendingLocationName}</td>
                      <td>
                        <NavLink href={link}>{linkText}</NavLink>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div className="text-sm">
        (What would be helpful here? I feel like I should say something...)
      </div>
    </Page>
  );
}

const HomeInit = start({}, Home as any);
export default HomeInit;

export async function getServerSideProps(context: any) {
  const newSessionId = `r-${nanoid()}`;
  const filesShuffled = {
    people: shuffle(imageFiles.people).slice(0, 40),
    places: shuffle(imageFiles.places).slice(0, 40),
    factions: shuffle(imageFiles.factions).slice(0, 40),
  };
  const otherProps = await getAuthProps(context);
  return { props: { newSessionId, filesShuffled, ...otherProps.props } };
}

async function loadSessions() {
  const res = await fetch("/api/session/list-sessions");
  const data = await res.json();
  if (!data.rows) {
    sessions.value = [];
    return;
  }
  sessions.value = data.rows.map((r: any) => {
    r.created = new Date(r.created);
    r.updated = new Date(r.updated);
    return r;
  });
}

const LoggedOutHome = ({ filesShuffled }: { filesShuffled: FilesShuffled }) => {
  function onSignIn() {
    signIn();
  }
  return (
    <Page title="World Wanderer" suppressControls>
      <div>
        <div className="text-2xl font-header text-center font-bold">World Wanderer</div>
        <PortraitCarousel filesShuffled={filesShuffled} />
        <div className="w-1/2 mx-auto flex flex-col items-center">
          <div>To get started you must first:</div>
          <div className="my-4">
            <Button className="px-12" onClick={onSignIn}>
              Sign In
            </Button>
          </div>
          <div>
            World Wanderer is a roleplaying game where you can explore mysterious cities and
            interact with the people who live there, powered by Large Language Models.
          </div>
          <div className="mt-4">
            <A className="mr-4" href="/about">
              About
            </A>{" "}
            <A href="/tos#privacy">Privacy</A> & <A href="/tos">Terms of Service</A>
          </div>
        </div>
      </div>
    </Page>
  );
};

const NewBeginning = ({
  filesShuffled,
  newSessionId,
}: {
  filesShuffled: FilesShuffled;
  newSessionId: string;
}) => {
  const loadedData = useSignal<null | {
    inviteCode: string;
    openaiKey: string;
    openrouterKey: string;
    allowGpt35: boolean;
    allowGpt4: boolean;
  }>(null);
  useEffect(() => {
    if (!loadedData.value) {
      fetch("/api/auth/get-settings").then((res) => {
        if (res.ok) {
          res.json().then((data) => {
            loadedData.value = {
              inviteCode: data.inviteCode,
              openaiKey: data.openaiKey,
              openrouterKey: data.openrouterKey,
              allowGpt35: data.allowGpt35,
              allowGpt4: data.allowGpt4,
            };
          });
        } else {
          loadedData.value = {
            inviteCode: "",
            openaiKey: "",
            openrouterKey: "",
            allowGpt35: false,
            allowGpt4: false,
          };
        }
      });
    }
  }, [loadedData]);
  if (!loadedData.value) {
    return (
      <Page title="A Life Lived">
        <PageMessage>Loading...</PageMessage>
      </Page>
    );
  }
  let hasAccess = true;
  if (
    loadedData.value.openaiKey ||
    loadedData.value.openrouterKey ||
    loadedData.value.allowGpt35 ||
    loadedData.value.allowGpt4
  ) {
    hasAccess = true;
  }
  return (
    <Page title="World Wanderer" suppressControls>
      <FreeChunk>
        <div className="relative w-full">
          <div className="w-full mt-12 mb-24">
            <PortraitCarousel filesShuffled={filesShuffled} />
          </div>
          <div
            className="bg-white w-1/2 absolute top-0 left-1/2 transform -translate-x-1/2 mt-4 rounded border py-6 shadow-xl"
            style={{ minHeight: "500px" }}
          >
            <div className="text-2xl font-header text-center font-bold">World Wanderer</div>
            {!hasAccess && (
              <div>
                <AccountSetup
                  inviteCode={loadedData.value.inviteCode}
                  onSetInvite={(inviteCode) => {
                    loadedData.value = {
                      ...loadedData.value,
                      inviteCode,
                      allowGpt35: true,
                    } as any;
                  }}
                />
              </div>
            )}
            <div className="w-1/2 mx-auto flex flex-col items-center">
              <div className="my-4">
                <NewCharacterLink newSessionId={newSessionId} disabled={!hasAccess} />
              </div>
            </div>
          </div>
        </div>
      </FreeChunk>
    </Page>
  );
};

const AccountSetup = ({
  inviteCode,
  onSetInvite,
}: {
  inviteCode: string;
  onSetInvite: (_value: string) => void;
}) => {
  const currentInviteCode = useSignal(inviteCode || "");
  const isCheckingCode = useSignal(false);
  async function onInviteCodeUpdate(e: any) {
    const inviteCode = e.target.value;
    currentInviteCode.value = inviteCode;
    isCheckingCode.value = true;
    const resp = await fetch("/api/auth/maybe-set-invite-code", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        inviteCode,
      }),
    });
    const data = await resp.json();
    if (data.ok) {
      onSetInvite(inviteCode);
    }
    isCheckingCode.value = false;
  }
  return (
    <div className="px-4">
      <div>To use this application you'll need a way to use an LLM...</div>
      <div className="m-4 bg-gray-300 p-4 rounded">
        {isCheckingCode.value && (
          <div className="float-right text-xs text-gray-600">Checking...</div>
        )}
        Enter an invite code: <br />
        <TextInput
          signal={currentInviteCode}
          onInput={onInviteCodeUpdate}
          placeholder="Invite code..."
        />
      </div>
      <div className="m-4 bg-gray-300 p-4 rounded flex justify-between items-center">
        <div>
          Connect to{" "}
          <A href="https://openrouter.ai/" blank>
            OpenRouter.ai
          </A>
          :
        </div>
        <NavLink href={OPENROUTER_ENDPOINT} blank>
          Connect
        </NavLink>
      </div>
      <div className="m-4 bg-gray-300 p-4 rounded flex justify-between items-center">
        <div>Enter your own OpenAI key in:</div>
        <NavLink href="/settings">Settings</NavLink>
      </div>
      <div>Once you've setup one of these you can...</div>
    </div>
  );
};

type PanelType = "people" | "places" | "factions";

const PortraitCarousel = ({ filesShuffled }: { filesShuffled: FilesShuffled }) => {
  const targetWidth = useSignal(1000);
  const showPeopleFiles = useSignal(filesShuffled.people);
  const showPlacesFiles = useSignal(filesShuffled.places);
  const showFactionsFiles = useSignal(filesShuffled.factions);
  const currentSwapType = useSignal<PanelType>("people");
  function swapImage(type: PanelType, index: number) {
    const sourceSignal = {
      people: showPeopleFiles,
      places: showPlacesFiles,
      factions: showFactionsFiles,
    }[type];
    const newFiles = [...sourceSignal.value];
    const newIndex =
      Math.floor(Math.random() * (sourceSignal.value.length - numImages)) + numImages;
    [newFiles[index], newFiles[newIndex]] = [newFiles[newIndex], newFiles[index]];
    sourceSignal.value = newFiles;
  }
  useEffect(() => {
    // Get the width of the window...
    targetWidth.value = window.innerWidth;
  }, [targetWidth]);
  const types: PanelType[] = ["people", "places", "factions"];
  useEffect(() => {
    const id = setInterval(() => {
      const t = currentSwapType.value;
      currentSwapType.value = types[(types.indexOf(t) + 1) % types.length];
      const index1 = Math.floor(Math.random() * showPeopleFiles.value.length);
      swapImage(t, index1);
    }, 10000);
    return () => {
      clearInterval(id);
    };
  });
  const IMAGE_WIDTH = 200;
  const FORCE_HEIGHT = 250;
  const FORCE_PLACES_HEIGHT = 200;
  const numImages = Math.floor(targetWidth.value / IMAGE_WIDTH) + 1;
  const images = showPeopleFiles.value ? showPeopleFiles.value.slice(0, numImages) : [];
  const placeImages = showPlacesFiles.value ? showPlacesFiles.value.slice(0, numImages) : [];
  return (
    <div>
      <div className="flex flex-row overflow-clip -mx-12" style={{ height: `${FORCE_HEIGHT}px` }}>
        {images.map((f, i) => (
          <img
            onClick={() => swapImage("people", i)}
            key={i}
            src={f.url}
            alt=""
            width={Math.floor(f.height! * (FORCE_HEIGHT / f.width!))}
          />
        ))}
      </div>
      <div
        className="flex flex-row overflow-clip -mx-12"
        style={{ height: `${FORCE_PLACES_HEIGHT}px` }}
      >
        {placeImages.map((f, i) => (
          <img
            onClick={() => swapImage("places", i)}
            key={i}
            src={f.url}
            alt=""
            width={Math.floor(f.height! * (FORCE_PLACES_HEIGHT / f.width!))}
          />
        ))}
      </div>
    </div>
  );
};

const NewCharacterLink = ({
  newSessionId,
  disabled,
}: {
  newSessionId: string;
  disabled?: boolean;
}) => {
  const actualSessionId = overrideSessionId.value || newSessionId;
  function onNewSession() {
    setTimeout(() => {
      overrideSessionId.value = `r-${nanoid()}`;
    }, 500);
  }
  if (disabled) {
    return (
      <NavLink href="" disabled>
        Create a new character
      </NavLink>
    );
  }
  return (
    <NavLink href={`/start/${encodeURIComponent(actualSessionId)}`} onClick={onNewSession} primary>
      Create a new character
    </NavLink>
  );
};

if (typeof window !== "undefined") {
  (window as any).sessions = sessions;
}
